// Global styles for the site. Keep this as small as possible

html, body { height: 100%; overflow: hidden; }
body {
  margin: 0;
  font-family: var(--body-font-family);
  color: var(--body-text-color);
  color-scheme: var(--color-scheme);
  max-height: 100%;
  overflow-y: auto;
}


hr {
    background-color: var(--hr-color);
}

.accent {
    background-color: var(--accent-bg-color) !important;
    color: var(--accent-text-color) !important;
    box-shadow: inset 0px 0px 8px 1px var(--accent-bg-color) !important;
    font-size: var(--accent-text-size) !important;
}

.text-muted {
    color: var(--text-muted-color) !important;
}
